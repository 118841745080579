import type { I18nLocale } from "./en";

export default {
  title: "Genotic",
  common: {
    cancel: "Anuluj",
    text: "Tekst",
    edit: "Edytuj",
    save: "Zapisz",
    day: "Dzień | Dni",
    download: "Pobierz | Pobrane",
    search: "Szukaj",
    scrollToTop: "Przewiń do góry",
    delete: "Usuń",
  },
  unit: {
    pc: "szt",
  },
  footer: {
    termsAndConditions: "Regulamin",
    privacyPolicy: "Polityka prywatności",
    new: "Nowe",
    hiring: "Zatrudniamy!",
  },
  header: {
    all: "Wszystko",
    myAccount: "Konto",
    cart: "Koszyk",
    signOut: "Wyloguj",
    languageDisplay: "Polski (PLN)",
    countryDisplay: "Polska (PLN)",
  },
  profile: {
    info: "Informacje",
    addresses: "Adresy",
    giftCards: "Karty podarunkowe",
    orders: "Zamówienia",
  },
  order: {
    cart: {
      empty: "Twój koszyk jest pusty",
      title: "Koszyk",
    },
    info: "Informacje o zamówieniu",
    addToCart: "Dodaj do koszyka",
    addedToCart: "Produkt {product} został dodany do twojego koszyka",
    goToCart: "Przejdź do koszyka",
    goToCheckout: "Przejdź do kasy",
    shippingTo: "Wysyłka do",
    subtotal: "Suma częściowa",
    shipping: {
      country: "Polska",
    },
  },
  auth: {
    login: "Zaloguj się",
    register: "Zarejestruj się",
    logout: "Wyloguj się",
    email: "E-mail",
    password: "Hasło",
    password2: "Powtórz hasło",
    firstName: "Imię",
    lastName: "Nazwisko",
  },
  product: {
    addToCart: "Dodaj do koszyka",
    addedToCart: {
      toastTitle: "Produkt {product} został dodany do twojego koszyka",
      toastDescription: "Możesz teraz go tam zobaczyć...",
    },
    outOfStock: "Brak w magazynie",
    synonyms: "Synonimy",
    noProductsFound: "Nie znaleziono produktów",
    description: {
      recommendedDilution: "Zalecane rozcieńczenie",
      applications: "Zastosowanie | Zastosowania",
      dilution: "Rozcieńczenie",
      testedApplications: "Przetestowane zastosowania",
      targetSpecies: "Gatunki docelowe",
      targetAliases: "Aliasy docelowe",
      species: "Gatunki",
      uniProtIdAndGenId: "UniProt ID i Gen ID",
      images: "Obraz | Obrazy",
      publications: "Publikacja | Publikacje",
      authors: "Autor | Autorzy",
      performanceData: "Dane wydajności",
      support: "Wsparcie",
      productSpecificProtocols: "Specyficzne protokoły produktu",
      frequentlyAskedQuestions: "Często zadawane pytania",
      contact: "Kontakt",
      productDetails: "Szczegóły produktu",
      targetInfo: "Informacje o celu",
      relatedProducts: "Powiązany produkt | Powiązane produkty",
    },
  },
  ui: {
    availability: {
      available: "Dostępny",
      unavailable: "Niedostępny",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Kategoria | Kategorie",
    },
  },
  categories: {
    title: "Kategorie",
    noCategoriesFound: "Nie znaleziono żadnej kategorii",
  },
  error: {
    somethingWentWrong: "Coś poszło nie tak",
    backToHome: "Wróć do strony głównej",
    "404": "Ta strona nie istnieje",
  },
} satisfies I18nLocale;
