const en = {
  title: "Genotic",
  common: {
    cancel: "Cancel",
    text: "Text",
    edit: "Edit",
    save: "Save",
    day: "Day | Days",
    download: "Download | Downloads",
    delete: "Delete",
    search: "Search",
    scrollToTop: "Scroll to top",
  },
  unit: {
    pc: "pc",
  },
  footer: {
    termsAndConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    new: "New",
    hiring: "We're hiring!",
  },
  header: {
    all: "All",
    myAccount: "My account",
    cart: "Cart",
    signOut: "Sign out",
    languageDisplay: "English (USD)",
    countryDisplay: "United States (USD)",
  },
  profile: {
    info: "Info",
    addresses: "Addresses",
    giftCards: "Gift cards",
    orders: "Orders",
  },
  order: {
    cart: {
      empty: "Your cart is empty",
      title: "Cart",
    },
    info: "Ordering info",
    addToCart: "Add to cart",
    addedToCart: "Product {product} was added to your cart",
    goToCart: "Go to cart",
    goToCheckout: "Go to checkout",
    shippingTo: "Shipping to",
    subtotal: "Subtotal",
    shipping: {
      country: "United States",
    },
  },
  auth: {
    login: "Login",
    register: "Register",
    logout: "Log out",
    email: "Email",
    password: "Password",
    password2: "Repeat password",
    firstName: "First name",
    lastName: "Last name",
  },
  product: {
    addToCart: "Add to cart",
    addedToCart: {
      toastTitle: "Product {product} was added to your cart",
      toastDescription: "You can now view it there ...",
    },
    outOfStock: "Out of stock",
    synonyms: "Synonyms",
    description: {
      recommendedDilution: "Recommended dilution",
      applications: "Application | Applications",
      dilution: "Dilution",
      testedApplications: "Tested applications",
      targetSpecies: "Target species",
      targetAliases: "Target aliases",
      species: "Species",
      uniProtIdAndGenId: "UniProt ID and Gen ID",
      images: "Image | Images",
      publications: "Publication | Publications",
      authors: "Author | Authors",
      performanceData: "Performance data",
      support: "Support",
      productSpecificProtocols: "Product specific protocols",
      frequentlyAskedQuestions: "Frequently asked questions",
      contact: "Contact",
      productDetails: "Product details",
      targetInfo: "Target info",
      relatedProducts: "Related product | Related products",
    },
    noProductsFound: "No products found",
  },
  ui: {
    availability: {
      available: "Available",
      unavailable: "Unavailable",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "@:categories.title",
    },
  },
  categories: {
    title: "Categories",
    noCategoriesFound: "No categories found",
  },
  error: {
    somethingWentWrong: "Something went wrong",
    backToHome: "Back to homepage",
    "404": "This page could not be found",
  },
};

export type I18nLocale = typeof en;

export default en;
