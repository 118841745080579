import type { I18nLocale } from "./en";

export default {
  title: "Genotic",
  common: {
    cancel: "Abbrechen",
    text: "Text",
    edit: "Bearbeiten",
    save: "Speichern",
    day: "Tag | Tage",
    download: "Herunterladen | Herunterladungen",
    search: "Suchen",
    scrollToTop: "Nach oben scrollen",
    delete: "Löschen",
  },
  unit: {
    pc: "Stk",
  },
  footer: {
    termsAndConditions: "Nutzungsbedingungen",
    privacyPolicy: "Datenschutzrichtlinie",
    new: "Neu",
    hiring: "Karriere",
  },
  header: {
    all: "Alle",
    myAccount: "Mein Konto",
    cart: "Warenkorb",
    signOut: "Abmelden",
    languageDisplay: "Deutsch (EUR)",
    countryDisplay: "Deutschland (EUR)",
  },
  profile: {
    info: "Info",
    addresses: "Adressen",
    giftCards: "Geschenkkarten",
    orders: "Bestellungen",
  },
  order: {
    cart: {
      empty: "Ihr Warenkorb ist leer",
      title: "Warenkorb",
    },
    info: "Bestellinformationen",
    addToCart: "In den Warenkorb",
    addedToCart: "Produkt {product} wurde Ihrem Warenkorb hinzugefügt",
    goToCart: "Zum Warenkorb",
    goToCheckout: "Zur Kasse",
    shippingTo: "Versand nach",
    subtotal: "Zwischensumme",
    shipping: {
      country: "Deutschland",
    },
  },
  auth: {
    login: "Anmelden",
    register: "Registrieren",
    logout: "Abmelden",
    email: "E-Mail",
    password: "Passwort",
    password2: "Passwort bestätigen",
    firstName: "Vorname",
    lastName: "Nachname",
  },
  product: {
    addToCart: "In den Warenkorb",
    addedToCart: {
      toastTitle: "Produkt {product} wurde Ihrem Warenkorb hinzugefügt",
      toastDescription: "Sie können es jetzt dort ansehen...",
    },
    outOfStock: "Nicht vorrätig",
    synonyms: "Synonyme",
    noProductsFound: "Keine Produkte gefunden",
    description: {
      recommendedDilution: "Empfohlene Verdünnung",
      applications: "Anwendung | Anwendungen",
      dilution: "Verdünnung",
      testedApplications: "Getestete Anwendungen",
      targetSpecies: "Zielarten",
      targetAliases: "Zielalias",
      species: "Arten",
      uniProtIdAndGenId: "UniProt ID und Gen ID",
      images: "Bild | Bilder",
      publications: "Veröffentlichung | Veröffentlichungen",
      authors: "Autor | Autoren",
      performanceData: "Leistungsdaten",
      support: "Unterstützung",
      productSpecificProtocols: "Produktspezifische Protokolle",
      frequentlyAskedQuestions: "Häufig gestellte Fragen",
      contact: "Kontakt",
      productDetails: "Produktdetails",
      targetInfo: "Zielinformationen",
      relatedProducts: "Ähnliches Produkt | Ähnliche Produkte",
    },
  },
  ui: {
    availability: {
      available: "Verfügbar",
      unavailable: "Nicht verfügbar",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Kategorie | Kategorien",
    },
  },
  categories: {
    title: "Kategorien",
    noCategoriesFound: "Keine Kategorien gefunden",
  },
  error: {
    somethingWentWrong: "Quelque chose s'est mal passé",
    backToHome: "Zurück zur Startseite",
    "404": "Diese Seite konnte nicht gefunden werden",
  },
} satisfies I18nLocale;
