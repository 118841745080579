import type { I18nLocale } from "./en";

export default {
  title: "Genotic",
  common: {
    cancel: "Annuler",
    text: "Texte",
    edit: "Modifier",
    save: "Enregistrer",
    day: "Jour | Jours",
    download: "Télécharger | Téléchargements",
    search: "Rechercher",
    scrollToTop: "Défiler vers le haut",
    delete: "Supprimer",
  },
  unit: {
    pc: "pce",
  },
  footer: {
    termsAndConditions: "Termes et conditions",
    privacyPolicy: "Politique de confidentialité",
    new: "Nouveau",
    hiring: "Nous recrutons !",
  },
  header: {
    all: "Tous",
    myAccount: "Mon compte",
    cart: "Panier",
    signOut: "Se déconnecter",
    languageDisplay: "Français (EUR)",
    countryDisplay: "France (EUR)",
  },
  profile: {
    info: "Infos",
    addresses: "Adresses",
    giftCards: "Cartes cadeaux",
    orders: "Commandes",
  },
  order: {
    cart: {
      empty: "Votre panier est vide",
      title: "Panier",
    },
    info: "Informations sur la commande",
    addToCart: "Ajouter au panier",
    addedToCart: "Le produit {product} a été ajouté à votre panier",
    goToCart: "Aller au panier",
    goToCheckout: "Passer à la caisse",
    shippingTo: "Expédition vers",
    subtotal: "Sous-total",
    shipping: {
      country: "France",
    },
  },
  auth: {
    login: "Connexion",
    register: "Inscription",
    logout: "Déconnexion",
    email: "E-mail",
    password: "Mot de passe",
    password2: "Confirmer le mot de passe",
    firstName: "Prénom",
    lastName: "Nom",
  },
  product: {
    addToCart: "Ajouter au panier",
    addedToCart: {
      toastTitle: "Le produit {product} a été ajouté à votre panier",
      toastDescription: "Vous pouvez maintenant le voir là...",
    },
    outOfStock: "Rupture de stock",
    synonyms: "Synonymes",
    noProductsFound: "Aucun produit trouvé",
    description: {
      recommendedDilution: "Dilution recommandée",
      applications: "Application | Applications",
      dilution: "Dilution",
      testedApplications: "Applications testées",
      targetSpecies: "Espèces cibles",
      targetAliases: "Alias cibles",
      species: "Espèces",
      uniProtIdAndGenId: "ID UniProt et ID Génétique",
      images: "Image | Images",
      publications: "Publication | Publications",
      authors: "Auteur | Auteurs",
      performanceData: "Données de performance",
      support: "Support",
      productSpecificProtocols: "Protocoles spécifiques au produit",
      frequentlyAskedQuestions: "Questions fréquentes",
      contact: "Contact",
      productDetails: "Détails du produit",
      targetInfo: "Informations sur la cible",
      relatedProducts: "Produit lié | Produits liés",
    },
  },
  ui: {
    availability: {
      available: "Disponible",
      unavailable: "Indisponible",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Catégorie | Catégories",
    },
  },
  categories: {
    title: "Catégories",
    noCategoriesFound: "Aucune catégorie trouvée",
  },
  error: {
    somethingWentWrong: "Quelque chose s'est mal passé",
    backToHome: "Retour à l'accueil",
    "404": "Cette page n'a pas été trouvée",
  },
} satisfies I18nLocale;
