import de from "~/src/i18n/locales/de";
import en from "~/src/i18n/locales/en";
import es from "~/src/i18n/locales/es";
import fr from "~/src/i18n/locales/fr";
import pl from "~/src/i18n/locales/pl";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    de,
    en,
    es,
    fr,
    pl,
  },
}));
