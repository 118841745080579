import type { I18nLocale } from "./en";

export default {
  title: "Genotic",
  common: {
    cancel: "Cancelar",
    text: "Texto",
    edit: "Editar",
    save: "Guardar",
    day: "",
    download: "Descargar | Descargas",
    search: "Buscar",
    scrollToTop: "Ir arriba",
    delete: "Eliminar",
  },
  unit: {
    pc: "ud",
  },
  footer: {
    termsAndConditions: "Términos y condiciones",
    privacyPolicy: "Política de privacidad",
    new: "Nuevo",
    hiring: "Estamos contratando!",
  },
  header: {
    all: "Todo",
    myAccount: "Mi cuenta",
    cart: "Carrito",
    signOut: "Cerrar sesión",
    languageDisplay: "Español (EUR)",
    countryDisplay: "Brasil (EUR)",
  },
  profile: {
    info: "Información",
    addresses: "Direcciones",
    giftCards: "Tarjetas de regalo",
    orders: "Pedidos",
  },
  order: {
    cart: {
      empty: "Tu carrito está vacío",
      title: "Carrito",
    },
    info: "Información de pedido",
    addToCart: "Añadir al carrito",
    addedToCart: "El producto {product} ha sido añadido a tu carrito",
    goToCart: "Ir al carrito",
    goToCheckout: "Ir a la caja",
    shippingTo: "Envío a",
    subtotal: "Subtotal",
    shipping: {
      country: "España",
    },
  },
  auth: {
    login: "Iniciar sesión",
    register: "Registrarse",
    logout: "Cerrar sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    password2: "Confirmar contraseña",
    firstName: "Nombre",
    lastName: "Apellido",
  },
  product: {
    addToCart: "Añadir al carrito",
    addedToCart: {
      toastTitle: "El producto {product} ha sido añadido a tu carrito",
      toastDescription: "Ahora puedes verlo ahí...",
    },
    outOfStock: "Agotado",
    synonyms: "Sinónimos",
    noProductsFound: "No se han encontrado productos",
    description: {
      recommendedDilution: "Dilución recomendada",
      applications: "Aplicación | Aplicaciones",
      dilution: "Dilución",
      testedApplications: "Aplicaciones probadas",
      targetSpecies: "Especies objetivo",
      targetAliases: "Alias objetivo",
      species: "Especies",
      uniProtIdAndGenId: "ID UniProt y ID Genético",
      images: "Imagen | Imágenes",
      publications: "Publicación | Publicaciones",
      authors: "Autor | Autores",
      performanceData: "Datos de rendimiento",
      support: "Soporte",
      productSpecificProtocols: "Protocolos específicos del producto",
      frequentlyAskedQuestions: "Preguntas frecuentes",
      contact: "Contacto",
      productDetails: "Detalles del producto",
      targetInfo: "Información del objetivo",
      relatedProducts: "Producto relacionado | Productos relacionados",
    },
  },
  ui: {
    availability: {
      available: "Disponible",
      unavailable: "No disponible",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Categoría | Categorías",
    },
  },
  categories: {
    title: "Categorías",
    noCategoriesFound: "No se han encontrado categorías",
  },
  error: {
    somethingWentWrong: "Algo salió mal",
    backToHome: "Volver a la página de inicio",
    "404": "Esta página no se ha encontrado",
  },
} satisfies I18nLocale;
